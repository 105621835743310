.loader {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid black;
    width: 70px;
    height: 70px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }


  /* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.modalLoader{
    background: transparent !important; 
    box-shadow: unset !important; 
    margin: unset !important; 
    padding: unset !important; 
    overflow-y: unset !important;
}

.rs-modalLoader {
  .modal-content{
    background-color: transparent !important;
    border: unset !important;
    align-items: center !important;
  }
}

