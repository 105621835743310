$button : white;

.input_login{
    width: 270px;
    margin-top: 5px;
    margin-bottom: 15px;
    padding: 5px;
}
.btn_submit{
    color: $button;
    width: 100%;
    border: 1px solid transparent;
    background-color: #159895;
    border-radius: 2px;
    margin-top: 10px;
    padding: 5px;

    font-size: 18px;
    font-weight: 600;

    &:hover{
        cursor: pointer;
        transition: 300ms;
        background-color: white;
        color: black;
        border: 1px solid black;
    }
}

.login_text{
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px;
}

.form_login{
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    padding: 40px 25px;
    border-radius: 8px;
    border: 1px solid #159895;

}

@media only screen and (max-width: 768px) {
    .input_login {
        width: 269px;
    }
}
