@import "react-slideshow-image/dist/styles.css";

body {
  background-color: #f9f9f9 !important;
  padding-right: 0 !important
}

.btn_halaman{
  border-color: #1a5f7a;
  color: white;
  border: unset;
  padding: 6px 11px;
  // padding: 8px 10px;
  margin-right: 10px;

  &:disabled{
    background-color: #5a5a5a;
  }

  // &:not(disabled):hover{
  //   background-color: red;
  // }
}

.div_btn_halaman{
  margin-bottom: 20px;
}

.header_front {
  background-color: #159895;
  color: white;
  height: 55px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  width: 100%;
  justify-content: space-between;
}

.footer_front {
  background-color: #1a5f7a;
  color: white;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  align-items: center;
  padding: 10px 20px;
}

.content_card {
  background-color: white;
  padding: 12px 14px;
  width: 100%;
  // display: block;

  &:hover{
    color: black !important;
  }

  // div{
  //     display: block;
  // }
}

.div-timer{
  text-align: center;
  font-size: 25px;
  margin-top: 20px;
}

.title_header {
  font-size: 22px;
  font-weight: 100;
  letter-spacing: 1px;
  text-decoration: none;
  color: white;

  text-align: center;
  width: 100%;

  &:hover{
      transition: 300ms;
      color: white !important;
  }
}

.btn_front {
  padding: 8px 10px;
  background-color: #1a5f7a;
  color: white;
  height: fit-content;
  border: unset;
  border-radius: 5px;

  &:hover {
    background-color: black;
    cursor: pointer;
    transition: 200ms;
  }

  a {
    text-decoration: none;
    color: white;
  }
}

.div_button_after_login {
  width: 15%;
  position: absolute;
  right: 0;
}

.div_latest_post {
  // padding: 50px 50px 0px 50px;
  // padding: 5% 6% 0px 4%;
  padding: 5% 6% 0px 6%;
}

.content_home {
  display: flex;
  padding: 20px 6%;
  // padding: 20px 50px;
  flex-wrap: wrap;
  // margin-top: 20px;
}


.list_card_home {
  width: 70%;
}

.sidebar_home {
  width: 30%;
  padding-left: 25px;
  // padding-top: 45px;
}

.latest_post {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
  letter-spacing: 0.5px;
}

.divider {
  width: 20%;
  height: 4px;
  border-radius: 10px;
  background-color: #1a5f7a;
  margin-bottom: 15px;
}

.card_blog_home {
  width: 100%;
  // margin: 0px 3% 50px 3%;
  margin-bottom: 40px;
  border: 1px solid transparent;

  // width: 350px;
  // margin: 0px 30px 50px 30px;
  // border: 1px solid transparent;

  &:hover {
    cursor: pointer;
    // border: 1px solid #1A5F7A;
    transition: 200ms;

    .link_blog_home > .content_card > .judul_blog_home {
      text-decoration: underline;
      transition: 200ms;
    }

    .img_blog_home {
      transition: 200ms;
      filter: brightness(0.7);
    }
  }
}

.div_after_search{
  padding: 5% 6% 0px 6%;
}

.div_judul_after_search{
  font-size: 20px;
  margin-bottom: 10px;
}

.img_blog_home {
  // width: 215px;
  min-width: 215px;
  height: 170px;
  object-fit: cover;
}

.kategori_card {
  background-color: #1a5f7a;
  color: white;
  font-weight: 600;
  font-size: 14px;
  padding: 3px 8px;
  display: inline-block;
}

.tanggal_blog_home {
  color: black;
  font-size: 14px;
  margin-top: 8px;
}

.judul_blog_home {
  // padding: 7px;
  max-height: 75px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-size: 22px;
  font-weight: 600;
  margin-top: 10px;

  &:hover{
    color: black !important;
  }
}

.link_blog_home {
  color: black;
  text-decoration: none;

  display: flex;
}

.div_detailBlog_utama {
  display: flex;
  width: 100%;
}

.div_detail_blog {
  width: 66%;
  padding: 50px 0px 50px 80px;
}

.img_detail_blog {
  max-width: 100%;
  max-height: 500px;
}

.judul_detail_blog {
  font-size: 30px;
  font-weight: 700;
  margin-top: 20px;
}

.waktu_detail_blog {
  color: #5a5a5a;
  margin-top: 10px;
}

.desc_detail_blog {
  margin-top: 20px;
}

.div_utama_latestBlog {
  padding: 60px;
  padding-top: 50px;
  width: 30%;
}

.link_latestBlog {
  text-decoration: none;
  color: black;
}

.div_card_latestBlog {
  margin-bottom: 40px;

  &:hover {
    transition: 200ms;
    text-decoration: underline;
    // border: 1px solid #1A5F7A;

    .img_card_latestBlog {
      transition: 200ms;
      filter: brightness(0.7);
    }
  }
}

.img_card_latestBlog {
  width: 100%;
  height: 170px;
  object-fit: cover;
}

.div_title_lastBlog {
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 700;
}

.div_judul_latestBlog {
  max-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.div_search_sidebar {
  background-color: white;
  padding: 20px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.div_sidebar_ads {
  background-color: white;
  height: 400px;
  padding: 15px;
}

.txt_search_post {
  margin-bottom: 5px;
}

.div_content_search {
  display: flex;
  width: 100%;
}

.input_search_post {
  height: 35px;
  width: 100%;
  border-radius: 0.375rem;
  border: 1px solid black;
  // width: 75%;
}

.btn_search_post {
  background-color: #1a5f7a !important;
  color: white;
  border: unset !important;
  padding: 5px 10px;
  margin-left: 5px;

  &:hover {
    transition: 200ms;
    background-color: black !important;
  }
}

.img-kategori {
}

.nama-kategori {
  color: white;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 1px;
}

.div-banner-kategori {
  width: 100%;
  text-align: center;
  height: 250px;
  background-color: #5a5a5a;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}

.div-latest-post {
  width: 100%;
}



.link_card_featured_blog {
  width: 100%;
  height: 200px;
  text-decoration: none;
  background-color: #1a5f7a;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: black;

  padding: 10px;
  display: flex;
  align-items: flex-end;

  &:hover {
    .judul_featured_blog {
      text-decoration: underline;
    }
  }
}

.judul_featured_blog {
  max-height: 75px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-size: 22px;
  font-weight: 600;
  margin-top: 10px;
  word-break: break-all;
  color: white;
}

.tgl_featured_blog{
    color: white;
}

.df-1 {
  grid-area: 1 / 3 / 2 / 4;
}
.df-2 {
  grid-area: 1 / 1 / 2 / 3;
}
.df-3 {
  grid-area: 2 / 1 / 3 / 2;
}
.df-4 {
  grid-area: 2 / 2 / 3 / 3;
}
.df-5 {
  grid-area: 2 / 3 / 3 / 4;
}

@media only screen and (min-width: 769px) {
  .div_featured_blog {
    // padding: 20px 50px;
    padding: 20px 6%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .div_featured_blog {
    padding: 20px 30px;
  }

  .BlogSliderDivImg{
    display: flex;
    align-items: flex-end;
    justify-content: left;
    padding: 10px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 250px;
  }

  .BlogSliderSpanJudul{
    color: white;
    padding: 5px;
    max-height: 100px;
    font-size: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-break: break-all;
    text-decoration: none;
  }

//   .div_featured_blog {
//     display: grid;
//     grid-template-columns: 1fr;
//     grid-template-rows: repeat(5, 1fr);
//     grid-column-gap: 10px;
//     grid-row-gap: 10px;
//   }

//   .df-1 {
//     grid-area: 1 / 1 / 2 / 2;
//   }
//   .df-2 {
//     grid-area: 2 / 1 / 3 / 2;
//   }
//   .df-3 {
//     grid-area: 3 / 1 / 4 / 2;
//   }
//   .df-4 {
//     grid-area: 4 / 1 / 5 / 2;
//   }
//   .df-5 {
//     grid-area: 5 / 1 / 6 / 2;
//   }

  .div_latest_post {
    padding: 10% 6% 0px 6%;
  }

  .div_detailBlog_utama {
    display: block;
  }

  .div_detail_blog {
    width: 100%;
    padding: 35px;
  }

  .div_utama_latestBlog {
    padding: 30px;
    padding-top: 0px;
    width: 100%;
  }

  .div_card_latestBlog {
    margin-bottom: 35px;
  }

  .card_blog_home {
    width: 100%;
  }

  .content_home {
    padding: 20px 20px;
    display: block;
  }

  .list_card_home {
    width: 100%;
  }

  .latest_post {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
  }

  .divider {
    width: 100%;
    height: 3px;
  }

  .sidebar_home {
    width: 100%;
    padding: unset !important;
  }

  .link_blog_home {
    display: block;
  }

  .img_blog_home {
    width: 100%;
  }

  .judul_blog_home {
    max-height: 55px;
    font-size: 18px;
    -webkit-line-clamp: 2;
  }

  .tanggal_blog_home {
    font-size: 13px;
  }

  .kategori_card {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 769px) {
  // .div_detailBlog_utama {
  //     display: block;
  // }

  .input_search_post {
    width: 100%;
  }

  .img_card_latestBlog {
    height: 145px;
  }

  .div_detail_blog {
    width: 70%;
    padding: 35px;
  }

  .div_utama_latestBlog {
    padding: 30px;
    padding-top: 30px;
    width: 30%;
  }

  .div_card_latestBlog {
    margin-bottom: 35px;
  }

  // .card_blog_home{
  //     width: 44%;
  //     margin: 0px 3% 50px 3%;
  // }

  .card_blog_home {
    width: 100%;
  }

  .content_home {
    padding: 20px 20px;
  }
}
