input[type=checkbox]{
    height: 0;
    width: 0;
    visibility: hidden;
  }

.switch{
    display: none;
}
  
  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 58px;
    height: 30px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
  }
  
  label:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 2px;
    width: 28px;
    height: 28px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }
  
  input:checked + label {
    background: #bada55;
  }
  
  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }